import SVG from "svg.js";

import { ANIMATION_OPTIONS, ANIMATION_DURATION } from "js/core/utilities/extensions.js";
import { $, _ } from "js/vendor";

// jquery has a bug which prevents setting the stroke-opacity property via css("stroke-opacity", x). this patches it.
$.cssNumber.strokeOpacity = true;
$.cssNumber.strokeDashoffset = true;

$.Velocity.Easings.myCustomEasing = function(p, opts, tweenDelta) {
    return 0.5 - Math.cos(p * Math.PI) / 2;
};

SVG.Element.prototype.spinner = function(show, bounds) {
    if (this.spinnerSVG) {
        this.spinnerSVG.remove();
    }
    if (show) {
        this.spinnerSVG = this.nested().addClass("spinner");
        var group = this.spinnerSVG.group().addClass("circular");
        group.circle(30).addClass("path");

        //         this.spinnerSVG.move(this.bbox().width / 2 - 15, this.bbox().height / 2 - 15);
        if (bounds) {
            this.spinnerSVG.move(bounds.width / 2 - 15, bounds.height / 2 - 15);
        } else {
            this.spinnerSVG.move(parseInt(this.width()) / 2 - 15, parseInt(this.height()) / 2 - 15);
        }
    }
};

SVG.Element.prototype.setRectangleBounds = function(bounds) {
    const transforms = [];
    const css = {};
    if (bounds.rotate != undefined) {
        css.transformOrigin = `${bounds.width / 2}px ${bounds.height / 2}px`;
        transforms.push(`rotateZ(${bounds.rotate})`);
    } else {
        css.transformOrigin = null;
    }
    const x = Math.round(bounds.left);
    const y = Math.round(bounds.top);
    if (x !== 0) {
        transforms.push(`translateX(${x}px)`);
    }
    if (y !== 0) {
        transforms.push(`translateY(${y}px)`);
    }
    if (transforms.length > 0) {
        css.transform = transforms.join("");
    } else {
        css.transform = null;
    }
    $(this.node).attr("width", bounds.width + "px", "height", bounds.height + "px").css(css);
};

SVG.Element.prototype.setBounds = function(bounds, animate) {
    const props = {};

    let $el = $(this.node);

    let hasChanged = false;

    if (this instanceof SVG.Circle) {
        props.cx = bounds.left + bounds.width / 2;
        props.cy = bounds.top + bounds.height / 2;
        props.r = Math.min(bounds.width, bounds.height) / 2;
        hasChanged = !this.lastProps || this.lastProps.cx != props.cx || this.lastProps.cy != props.cy || this.lastProps.r != props.r;
    } else {
        props.translateX = Math.round(bounds.left);
        props.translateY = Math.round(bounds.top);
        props.width = Math.round(bounds.width);
        props.height = Math.round(bounds.height);
        hasChanged = !this.lastProps || this.lastProps.translateX != props.translateX || this.lastProps.translateY != props.translateY || this.lastProps.width != props.width || this.lastProps.height != props.height;
    }

    if (bounds.rotate != undefined) {
        $el.css("transform-origin", `${props.width / 2}px ${props.height / 2}px`);
        props.rotateZ = bounds.rotate;
    }

    if (hasChanged) {
        // don't animate if there are no lastProps
        if (animate && this.lastProps) {
            $el.velocity("finish");

            // force feed values from lastProps
            let animProps = _.mapValues(props, (value, key) => {
                if (this.lastProps.hasOwnProperty(key)) {
                    return [value, this.lastProps[key]];
                } else {
                    return value;
                }
            });

            $el.velocity(animProps, ANIMATION_OPTIONS);
        } else {
            // // velocity does some special stuff with transforms and doesn't support setting the initial transform state via css, so we just do a zero-duration animation using velocity to set the "unanimated" position
            // $el.velocity(props, {
            //     duration: 0,
            //     queue: false
            // });

            $el.transform(props);//.width(props.width).height(props.height);

            // if (this instanceof SVG.Rect || this instanceof SVG.Image) {
            // this.width(props.width).height(props.height);
            // }

            if (this instanceof SVG.Circle) {
                this.cx(props.cx).cy(props.cy).radius(props.r);
            }

            $.Velocity.hook($el, "translateX", props.translateX + "px");
            $.Velocity.hook($el, "translateY", props.translateY + "px");
            $.Velocity.hook($el, "width", props.width);
            $.Velocity.hook($el, "height", props.height);
        }
        this.lastProps = props;

        return this;
    }
};

SVG.Element.prototype.setPosition = function(x, y, animate) {
    if (typeof (x) == "object") {
        animate = y;
        y = x.y;
        x = x.x;
    }

    if (!this.lastProps || x != this.lastProps.translateX || y != this.lastProps.translateY) {
        var props = {
            translateX: x,
            translateY: y
        };

        if (this.currentPosition && animate) {
            $(this.node).velocity("finish");

            // force feed values from lastProps
            let animProps = _.mapValues(props, (value, key) => {
                if (this.lastProps.hasOwnProperty(key)) {
                    return [value, this.lastProps[key]];
                } else {
                    return value;
                }
            });
            $(this.node).velocity(animProps, ANIMATION_OPTIONS);
        } else {
            // velocity does some special stuff with transforms and doesn't support setting the initial transform state via css, so we just do a zero-duration animation using velocity to set the "unanimated" position
            // $(this.node).velocity(props, {
            //     duration: 0,
            //     queue: false
            // });
            // $(this.node).velocity("finish");
            $(this.node).transform(props);
            $.Velocity.hook($(this.node), "translateX", props.translateX + "px");
            $.Velocity.hook($(this.node), "translateY", props.translateY + "px");
        }

        this.lastProps = { translateX: x, translateY: y };
    }

    return this;
};

SVG.Element.prototype.setPositionAndScale = function(x, y, scale, animate) {
    // if (!this.lastProps || x != this.lastProps.translateX || y != this.lastProps.translateY || scale != this.lastProps.scale) {
    var props = {
        translateX: x,
        translateY: y,
        scale: scale
    };
    // $(this.node).css("transform-origin", "center");

    if (this.lastProps && animate) {
        $(this.node).velocity("finish");
        // force feed values from lastProps
        let animProps = _.mapValues(props, (value, key) => {
            if (this.lastProps.hasOwnProperty(key)) {
                return [value, this.lastProps[key]];
            } else {
                return value;
            }
        });

        $(this.node).velocity(animProps, ANIMATION_OPTIONS);
    } else {
        // set the transform via velocity because it will cache and flush the 'transform' css itself
        const $node = $(this.node);
        $.Velocity.hook($node, "translateX", props.translateX + "px");
        $.Velocity.hook($node, "translateY", props.translateY + "px");
        $.Velocity.hook($node, "scale", props.scale);
    }

    this.lastProps = { translateX: x, translateY: y, scale: scale };

    return this;
};

SVG.Element.prototype.setTransform = function(props, animate) {
    if (this.lastProps && animate) {
        $(this.node).velocity("finish");
        // force feed values from lastProps
        let animProps = _.mapValues(props, (value, key) => {
            if (this.lastProps.hasOwnProperty(key)) {
                return [value, this.lastProps[key]];
            } else {
                return value;
            }
        });
        $(this.node).velocity(animProps, ANIMATION_OPTIONS);
    } else {
        // set the transform via velocity because it will cache and flush the 'transform' css itself
        const $node = $(this.node);
        _.forEach(props, (value, key) => {
            switch (key) {
                case "translateX":
                case "translateY":
                    $.Velocity.hook($node, key, value + "px");
                    break;
                case "scaleX":
                case "scaleY":
                case "scale":
                    $.Velocity.hook($node, key, value);
                    break;
                case "skewX":
                case "skewY":
                    $.Velocity.hook($node, key, value + "deg");
                    break;
                default:
                    throw new Error("not implemented: " + key);
            }
        });
    }

    this.lastProps = _.clone(props);
};

SVG.Element.prototype.draw = function(points, animate, duration = ANIMATION_DURATION) {
    return new Promise(resolve => {
        if (_.isMatch(this.array().value, points)) {
            return;
        }

        if (animate && this.isPlotted) {
            if (this.isAnimating) {
                this.finish();
            }

            this.isAnimating = true;

            this.stop();
            $("body").velocity("finish");

            let animation = this.animate(duration, "e").plot(points).pause();

            let easing = $.Velocity.Easings[ANIMATION_OPTIONS.easing];

            let startTime;
            let doAnimation = timestamp => {
                if (!startTime) {
                    startTime = timestamp;
                }
                let percentComplete = easing(Math.min((timestamp - startTime) / duration, 1));

                animation.at(percentComplete);

                if (percentComplete < 1) {
                    window.requestAnimationFrame(doAnimation);
                } else {
                    this.isAnimating = false;
                    resolve();
                }
            };

            window.requestAnimationFrame(doAnimation);
        } else {
            this.plot(points);
            this.isPlotted = true;
            resolve();
        }
    });
};

SVG.Element.prototype.getTransform = function() {
    var transform = this.style("transform");
    return {
        x: /translateX\((.*?)px\)/.test(transform) ? parseFloat(/translateX\((.*?)px\)/.exec(transform)[1]) : 0,
        y: /translateY\((.*?)px\)/.test(transform) ? parseFloat(/translateY\((.*?)px\)/.exec(transform)[1]) : 0
    };
};

SVG.Element.prototype.animateColorTransition = function(color) {
    let width = parseInt(this.width());
    let height = parseInt(this.height());

    //Check to see if we are attached or not.
    if (!this.doc()) {
        return;
    }

    let circle = this.parent().circle(50).fill(color);
    this.after(circle);
    circle.center(width / 2, height / 2);
    circle.clipWith(this.clone().opacity(1));

    circle.animate(300).radius(Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) / 2).after(() => {
        this.style("fill", color);
        circle.clipper.remove();
        circle.remove();
    });
};
